<template>
	<div class="projeto">
		<Nav />
		<Icon />
		<div class="grid-box">
			<Breadcrumb :levels="breadcrumbLevels" />
			<div class="main-block" :class="pageOptions.FooterClass">
				<router-view :page-options="pageOptions" @changeLevels="changeLevels" />
			</div>
		</div>
	</div>
</template>

<script type="plain/text">
import Nav from "@/components/Nav.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Icon from "@/components/Icon.vue";
import PageOptions from "@/scripts/models/pageOptions.model";

export default {
	components: {
		Icon,
		Breadcrumb,
		Nav
	},
	data() {
		return {
			pageOptions: new PageOptions(),
			breadcrumbLevels: [
				// { pathName: Routes.app.DashboardPF, text: "Início" },
				{ text: "Início" },
				{ text: "Conselhos" }
			]
		};
	},
	methods: {
		changeLevels(levels) {
			this.breadcrumbLevels = levels;
		}
	}
};
</script>